import '../../styles/font.css';

import React, { useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeContext } from 'styled-components';
import WebFonts from '../../styles/fonts.style';
import GlobalStyles from '../../styles/global.style';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { StyledLayout } from './Layout.styled';
import { PactContext } from '../PactContext';
import { Cursor } from '../Cursor/Cursor';
import { LoadingAnimation } from '../LoadingAnimation/LoadingAnimation';
import { hexToRGB } from '../../utils/Tools';

export const Layout: React.FC<LayoutProps> = ({
  children,
  theme = 'pebble',
  language = 'en',
  headerOverlap = false,
  showMissingFrenchBanner = false,
  slugs = { en: '/', fr: '/fr' }
}) => {
  //#region Hooks / Lifecycles
  const themeContext = useContext(ThemeContext);

  const [isLoading, setIsLoading] = useState<boolean>(typeof window !== 'undefined' && window.hasLoaded ? false : typeof window !== 'undefined');

  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => {
    bindEvents();
    logBranding();
    setTimeout(() => {
      setIsLoading(false);
    }, 2500)
  }

  const destroy = (): void => {
    unbindEvents();
  }

  const bindEvents = (): void => {
    if (typeof window === 'undefined') return;

    window.addEventListener('resize', onResize);
    onResize();
  }

  const unbindEvents = (): void => {
    if (typeof window === 'undefined') return;

    window.removeEventListener('resize', onResize);
  }

  const onResize = (): void => {
    document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);
  }

  const logBranding = (): void => {
    console.log(`
Built with ❤️ by

                                $$\\         
                                $$ | 
 $$$$$$\\   $$$$$$\\   $$$$$$$\\ $$$$$$\\       
$$  __$$\\  \\____$$\\ $$  _____|\\_$$  _|      
$$ /  $$ | $$$$$$$ |$$ /        $$ |        
$$ |  $$ |$$  __$$ |$$ |        $$ |$$\\     
$$$$$$$  |\\$$$$$$$ |\\$$$$$$$\\   \\$$$$  |$$\\ 
$$  ____/  \\_______| \\_______|   \\____/ \\__|
$$ |                                        
$$ |                                        
\\__|                                        


careers@workwithpact.com
`, )
  }
  //#endregion

  //#region Templating
  return (
    <PactContext.Provider value={{ language, slugs, pageTheme: theme }}>
      <StyledLayout bg={theme}>
        <Helmet>
          <style type="text/css">{`
            :root{
              --background: ${themeContext[theme].background};
              --color: ${themeContext[theme].text};
              --hoverColor: ${themeContext[theme].hoverColor};
              --cursorColor: ${themeContext[theme].cursor};

              --backgroundRGB: ${hexToRGB(themeContext[theme].background).join(',')};
              --colorRGB: ${hexToRGB(themeContext[theme].text).join(',')};
              --hoverColorRGB: ${hexToRGB(themeContext[theme].hoverColor).join(',')};
            }
          `}</style>
        </Helmet>
        <GlobalStyles />
        <LoadingAnimation visible={typeof window !== 'undefined' && window?.hasLoaded ? false : true} />
        <Cursor />
        {(!isLoading || typeof window === 'undefined') && (
          <>
            <Header showMissingFrenchBanner={showMissingFrenchBanner} />
            <main className={`${headerOverlap ? 'overlap' : ''}`}>
              {children}
              <Footer />
            </main>
          </>
        )}
      </StyledLayout>
    </PactContext.Provider>
  )
  //#endregion
}

interface LayoutProps {
  theme?: ThemeBG;
  headerOverlap?: boolean;
  showMissingFrenchBanner?: boolean;
  language?: 'en' | 'fr';
  slugs?: { en: string, fr: string }
}