import styled from 'styled-components';
import { desktopHover, pxtorem } from '../../utils/Tools';

export const StyledLanguageSwitcher = styled.div`
  text-align: center;
  font: 400 ${pxtorem(16)}/120% var(--secondaryFont);
  text-transform: uppercase;
  .lang {
    transition: color .25s;
    &.active {
      border-bottom: 1px solid currentColor;
    }
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
    ${desktopHover('--color: var(--hoverColor)')}
  }
`;