import styled from 'styled-components';
import { desktopHover } from '../../utils/Tools';
import { BurgerProps } from './Burger';

export const StyledBurger = styled.button<BurgerProps>`
  --barWidth: 48px;
  --barGap: 6px;
  transition: color .25s;
  width: var(--barWidth);
  height: var(--logoHeight);
  background: transparent;
  position: relative;
  padding: 0;
  border: none;
  display: block;
  /* cursor: pointer; */
  ${desktopHover(`--color: var(--hoverColor);`, false)}

  .top, .bottom {
    --angle: 0deg;
    transition: background .25s, transform .55s cubic-bezier(.55,0,.1,1);
    position: absolute;
    height: 1px;
    width: var(--barWidth);
    background: var(--color);
    will-change: transform;
    left: 0;
    top: 50%;
  }

  .top {
    top: calc(50% - var(--barGap) / 2);
    transition-delay: .05s;
    ${p => p.isActive
      ? `transform: translateY(3px) rotate(45deg)`
      : `transform: none`
    }
  }

  .bottom {
    top: calc(50% + var(--barGap) / 2);
    ${p => p.isActive
      ? `transform: translateY(-3px) rotate(-45deg)`
      : `transform: none`
    }
  }
`