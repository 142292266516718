import styled, {css} from 'styled-components';
import { desktopHover, pxtorem, pxtovw } from '../../utils/Tools';

export const StyledMenu = styled.div`
  /* position: fixed; */
  height: 100%;
  transition: opacity .55s cubic-bezier(.22, 1, .36, 1);

  pointer-events: none;
  opacity: 0;

  .menu-opened & {
    opacity: 1;
    pointer-events: all;
  }

  position: fixed;
  padding-top: var(--header-height);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--background);
  z-index: 10;

  .items {
    padding-bottom: var(--header-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    pointer-events: none;

    .link {
      .menu-opened & {
        pointer-events: all;
      }
    }
    
    &:hover {
      .link {
        opacity: .5
      }
    }
  }

  .item {
    display: block;
    /* transition: transform .55s cubic-bezier(.22, 1, .36, 1);
    transform: translateY(50px);

    .menu-opened & {
      transform: translateY(0);
    }

    ${p => [...Array(5)].map((el, i: number) => {
      const x = i - 1;
      return css`
        &:nth-child(${i + 1}) {
          content: ${x};
          transition-delay: ${(i) * 0.1}s;
        }
      `
    })} */

  }

  .link.link {
    transition: .55s;
    transition-property: opacity, color;
    display: block;
    font: 300 max(${pxtovw(120)}, 44px)/max(${pxtovw(108)},40px) var(--primaryFont);
    margin-bottom: 0.625vw;
    position: relative;

    @media (min-width: 1920px) {
      font: 300 120px/108px var(--primaryFont);
    }
    /* z-index: 2; */
    
    ${desktopHover(`z-index: 3; opacity: 1`)}

    .count {
      transition: .55s;
      transition-property: opacity, color;
      font-size: 20px;
      font-family: var(--secondaryFont);
      position: absolute;
      left: 100%;
      top: 0;
      line-height: normal;
    }

    @media (max-height: 500px) {
      font: 400 6.25vmin/5.625vmin var(--primaryFont);
    }
  }

  .floatingImage {
    transition: opacity .25s;
    width: 18.90625vw;
    height: 23.59375vw;
    position: fixed;
    background: transparent;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 1;
    opacity: 0;
    &.visible {
      opacity: 1;
    }

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(60,60,60,0.5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    & > div, & > div > div {
      height: 100%;
    }

    @media (min-width: 1920px) {
      width: 363px;
      height: 453px;
    }
  }

  .dot {
    margin-inline: ${pxtorem(16)};
    padding-bottom: 4px;
  }

  .menu-bottom {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: auto;

    .bottom-link {
      font: 400 ${pxtorem(16)} /120% var(--secondaryFont);
      &:not(:last-child) {
        margin-right: ${pxtorem(16)};
      }
    }
  }
`;