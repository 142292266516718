import { createGlobalStyle } from 'styled-components';
import { desktopHover, pxtorem } from '../utils/Tools';

const GlobalStyles = createGlobalStyle`
  :root {
    --window-height: 100vh;
    --gutter: 50px;
    --primaryFont: 'ivypresto-display', sans-serif;
    --primaryFontText: 'ivypresto-text', sans-serif;
    --secondaryFont: 'SctoGrotesk', sans-serif;
    --black: ${p => p.theme.colors.black};
    --charcoal: ${p => p.theme.colors.charcoal};
    --pebble: ${p => p.theme.colors.pebble};
    --aqua: ${p => p.theme.colors.aqua};
    --cream: ${p => p.theme.colors.cream};
    --salmon: ${p => p.theme.colors.salmon};
    --cloud: ${p => p.theme.colors.cloud};
    --solitude: ${p => p.theme.colors.solitude};
    --seafoam: ${p => p.theme.colors.seafoam};
    --seaweed: ${p => p.theme.colors.seaweed};
    --white: ${p => p.theme.colors.white};
    --header-height: 156px;

    @media (max-width: 1300px) {
      --gutter: 40px;
    }

    @media (max-width: 1024px) {
      --header-height: 132px;
    }

    @media (max-width: 768px) {
      --header-height: 92px;
      --gutter: 24px;
    }
  }

  *, *:before, &:after {
    box-sizing: inherit;
  }

  html {
    background: var(--background);
    color: var(--color);
    font-size: ${p => p.theme.fonts.fontSize}px;
    font-display: fallback;
    font-family: var(--primaryFont);
    box-sizing: border-box;
    text-rendering: geometricPrecision;
    height:  -webkit-fill-available;
    @media (max-width: 1024px) {
      font-size: 16px;
    }
  }

  body {
    background: var(--background);
    color: var(--color);
    margin: 0;
    font-size: 1rem;
    line-height: ${pxtorem(30)};

    cursor: none;

    &.menu-opened, &.hide-scroll {
      overflow: hidden;
    }
  }

  body.disable-animations {
    *,*:before, *:after {
      transition: none !important;
    }
  }

  h1 {
    font-weight: 300;
  }
  
  h1, h2, h3, h4, h5 {
    margin-top: 0;
  }

  h1, h2, h3, h4, h5, p, span, a, input, select, button {
    color: var(--color);
  }

  input, select, button {
    font: inherit;
    font-family: var(--secondaryFont);
  }

  ul,ol {
    margin: 0;
    padding: 0;
  }

  a, button {
    text-decoration: none;
    cursor: none;
    ${desktopHover(`cursor: none;`)}
  }

  main {
    padding-top: var(--header-height);
    &.overlap {
      padding-top: 0;
    }

    overflow: hidden;
    position: relative;
    z-index: 1;
  }
`;

export default GlobalStyles;