import React, { useContext, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { LogoSvg } from '../../styles/svg/logo.svg';
import { PactContext } from '../PactContext';
import { StyledLoadingAnimation } from './LoadingAnimation.style';
import { NONAME } from 'dns';

export const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ visible }) => {
  //#region Hooks / Lifecycles
  const { pageTheme } = useContext(PactContext);
  const tl = useRef<gsap.core.Timeline>();
  const svgHolder = useRef<HTMLDivElement>();
  const curtain = useRef<HTMLDivElement>();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => {
    if (!visible) return;
    document.body.classList.add('hide-scroll');
    animateIn();
  }

  const destroy = (): void => { }

  const animateIn = (): void => {
    tl.current = gsap.timeline({
      defaults: {
        duration: 1.5,
        ease: 'power4.inOut'
      }
    });

    tl.current.to(svgHolder.current.querySelectorAll('path:not(:last-child)'), {
      stagger: .1,
      autoAlpha: 1,
      y: 0
    });

    tl.current.to(svgHolder.current.querySelectorAll('path:last-child'), {
      autoAlpha: 1,
      y: 0
    }, '>-1.5');

    tl.current.to(svgHolder.current.querySelectorAll('path'), {
      ease: 'power3.in',
      stagger: .1,
      autoAlpha: 0,
      duration: 1,
      y: 25
    }, '>-.4');

    tl.current.to(curtain.current, {
      height: 0,
      onComplete: () => {
        document.body.classList.remove('hide-scroll');
        window.hasLoaded = true;


        document.dispatchEvent(new CustomEvent('loading:done'));

        gsap.set(curtain.current, {
          display: 'none'
        });
      }
    }, '<.35');
  }
  //#endregion

  //#region Templating
  return (
    <StyledLoadingAnimation ref={curtain} pageTheme={pageTheme} visible={visible}>
      <div className="wrapper">
        <div className="logo" ref={svgHolder}>
          <LogoSvg />
        </div>
      </div>
    </StyledLoadingAnimation>
  )
  //#endregion
}

interface LoadingAnimationProps {
  visible: boolean;
}