import React from 'react';

export const LogoSvg: React.FC = () => {
  //#region Templating
  return (
    <svg viewBox="0 0 78 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.3257 6.6147C10.2034 6.6147 7.63215 7.53675 6.16286 11.1047L6.85669 6.45434L0 7.93764V8.49889C0.816273 8.4588 2.28556 9.10022 2.28556 11.4655V32.1915C2.28556 34.3964 1.10197 35.3987 0.0816273 35.5189V36H9.06063V35.4788C7.91785 35.3987 6.73425 34.5568 6.73425 32.1915V25.4967C8.04029 26.6592 9.79528 27.7016 12.1625 27.7016C17.4682 27.7016 21.3047 22.8107 21.3047 17.1982C21.3047 11.4254 17.9988 6.6147 12.3257 6.6147ZM11.6319 26.9399C9.50958 26.9399 7.79541 25.4165 6.73425 22.8107V11.265C7.71378 8.89978 9.50958 7.97773 11.0197 7.97773C15.1419 7.97773 16.652 13.8708 16.652 18.1203C16.652 23.412 15.1011 26.9399 11.6319 26.9399Z" fill="currentColor" />
      <path d="M28.4076 27.7016C31.0605 27.7016 33.8766 25.9777 34.8561 23.3319C35.0194 24.1737 35.3051 25.1359 35.6724 25.8976C36.2438 26.9399 37.1009 27.7016 38.5702 27.7016C40.2435 27.7016 41.3863 26.6192 41.9169 25.7773V24.9755C41.5904 25.2561 41.1823 25.4566 40.7333 25.4566C39.8762 25.4566 39.264 23.9733 38.9375 22.0089L38.0396 16.0757C37.1009 9.94209 35.5908 6.6147 29.7136 6.6147C25.7139 6.6147 22.5304 8.97995 22.5304 12.0668C22.5304 13.4699 23.4283 14.7528 24.9384 14.7528C26.1628 14.7528 26.9791 13.5902 26.9791 12.5078C26.9383 11.2249 25.8363 10.7439 25.8363 9.46102C25.8363 7.93764 27.3056 7.3363 28.9382 7.3363C31.4278 7.3363 32.5706 9.78174 33.2644 13.9911L33.5909 15.7951C30.2442 16.1158 21.9182 17.0379 21.9182 22.4098C21.9182 26.1782 25.0609 27.7016 28.4076 27.7016ZM30.1626 25.5368C28.0811 25.5368 26.3261 24.3341 26.3261 21.608C26.3261 18.0401 30.6523 17.0779 33.6725 16.3964C34.1215 18.7216 34.3664 20.8463 34.3664 21.9688C34.3664 24.3742 32.3257 25.5368 30.1626 25.5368Z" fill="currentColor" />
      <path d="M51.0333 27.7016C54.5025 27.7016 57.89 25.6971 59.2369 21.7684V20.7661C57.7268 23.7327 55.0739 24.5345 53.3597 24.5345C48.707 24.5345 45.401 20.7661 45.401 14.392C45.401 10.2227 47.4825 7.29621 51.0333 7.29621C53.9719 7.29621 55.278 9.14031 55.3188 10.343C55.3188 11.6258 54.4209 11.9465 54.4209 13.2294C54.4209 14.6325 55.5636 15.4744 56.6656 15.4744C57.89 15.4744 59.0736 14.2717 59.0736 12.4276C59.0736 9.50111 55.7269 6.6147 51.6047 6.6147C45.7276 6.6147 41.3605 11.4655 41.3605 17.0379C41.3605 22.8107 45.5235 27.7016 51.0333 27.7016Z" fill="currentColor" />
      <path d="M71.8996 7.97773L72.1445 6.37416C70.5528 6.85523 68.4305 7.13586 66.7979 7.13586H66.349V0H65.7368C64.9613 2.84632 62.186 6.53452 58.676 7.13586V7.97773H61.9003V21.7283C61.9003 25.8976 64.0226 27.6615 66.5122 27.6615C69.3692 27.6615 71.0425 26.1381 71.4507 25.6169V25.0557C70.3487 26.0178 66.349 26.8998 66.349 21.3274V7.97773H71.8996Z" fill="currentColor" />
      <path d="M75.143 27.7016C76.7348 27.7016 78 26.4588 78 24.8552C78 23.3319 76.7348 22.049 75.143 22.049C73.5513 22.049 72.2861 23.3319 72.2861 24.8552C72.2861 26.4588 73.5513 27.7016 75.143 27.7016Z" fill="currentColor" />
    </svg>
  )
  //#endregion
}