
export default class DeviceInfo{
  static check() {
    if (typeof window === 'undefined') return;
    
    const nua :string = window.navigator.userAgent.toLowerCase();
    
    return {
      // isDesktop: !window.isTablet,
      isIE: DeviceInfo.getInternetExplorerVersion() !== -1 ? true : false,
      isOpera: ( nua.indexOf('opr/') > -1 ) ? true : false,
      // isChrome: !window.isOpera && ( nua.indexOf("chrome") > -1 ) ? true : false,
      // isSafari: !window.isOpera && !window.isChrome && ( nua.indexOf("safari") > -1 ) ? true : false,
      isFirefox: ( nua.indexOf('firefox') > -1 ) ? true : false,
      isMobile: (nua.toLowerCase().match(/(ipad|iphone|ipod)/g) || nua.toLowerCase().indexOf('android') > -1) ? true : false
    };
    
  }
  
  static getInternetExplorerVersion () :number{
    
    let rv :number = -1;
    let re :RegExp = null;
    const ua :string = window.navigator.userAgent;
    
    if (window.navigator.appName === 'Microsoft Internet Explorer') {
      
      re  = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
      if (re.exec(ua) !== null) rv = parseFloat( RegExp.$1 );
      
    } else if (window.navigator.appName === 'Netscape') {
      
      re  = new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})');
      if (re.exec(ua) !== null) rv = parseFloat( RegExp.$1 );
    }
    
    return rv;
  }
}