import styled from 'styled-components';

export const StyledLayout = styled.div<StyledLayoutProps>`
  --background: ${p => p.theme[p.bg].background};
  --color: ${p => p.theme[p.bg].text};
  background: var(--background);
  color: var(--color);
`

interface StyledLayoutProps {
  bg: ThemeBG;
}