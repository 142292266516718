import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import DeviceInfo from '../../utils/DeviceInfo';
import { getDistance, Clamp, gearRatio, pxToPercent } from '../../utils/Tools';
import { StyledCursor } from './Cursor.style';
import { globalHistory } from '@reach/router';

const mouse: Vector = {
  x: 0,
  y: 0
};

const currentPos: Vector = {
  x: typeof window === 'undefined' ? 0 : window.innerWidth / 2,
  y: typeof window === 'undefined' ? 0 : window.innerHeight / 2
};

const scale = {
  value: pxToPercent(20),
  baseValue: pxToPercent(20),
  minValue: pxToPercent(8),
  downModifier: .6,
  upModifier: 1.2,
  maxValue: pxToPercent(24)
}

let preventAutoScale: boolean = false;
let isOutsideViewport: boolean = false;

const isMobile: boolean = DeviceInfo.check()?.isMobile || false;

export const Cursor: React.FC = () => {
  //#region Hooks / Lifecycles

  const requestRef = useRef<number>();
  const cursorRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isMobile) return;
    init();
    document.addEventListener('loading:done', onLoadDone);

    return () => {
      destroy();
      document.removeEventListener('loading:done', onLoadDone);
    }
  }, []);

  useEffect(() => {
    return globalHistory.listen(({ location }) => {
      resetCursor();
    })
  }, [])
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const onLoadDone = (): void => {
    destroy();
    init();
  }

  const init = (): void => {
    bindEvents();
  }

  const destroy = (): void => {
    unbindEvents();
    stopRAF();
  }

  //#region Events binding
  const bindEvents = (): void => {
    document.addEventListener('mousemove', onMousemove);
    document.addEventListener('mouseleave', scaleOut);
    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mouseenter', onMouseUp);

    bindLinks();
    bindColorChangers();
    bindSizeChangers();
    bindTextChangers();
  }

  const bindLinks = (): void => {
    const links: NodeListOf<HTMLElement> = document.querySelectorAll('a, button');
    for (let i: number = 0; i < links.length; i++) {
      links[i].addEventListener('mouseenter', onMouseenter);
      links[i].addEventListener('mouseleave', onMouseleave);
    }
  }

  const bindColorChangers = (): void => {
    const colorChangers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-cursor-color]');
    for (let i: number = 0; i < colorChangers.length; i++) {
      colorChangers[i].addEventListener('mouseenter', onMouseenterColorChanger);
      colorChangers[i].addEventListener('mouseleave', onMouseleaveColorChanger);
    }
  }

  const bindSizeChangers = (): void => {
    const sizeChangers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-cursor-size]');
    for (let i: number = 0; i < sizeChangers.length; i++) {
      sizeChangers[i].addEventListener('mouseenter', onMouseenterSizeChanger);
      sizeChangers[i].addEventListener('mouseleave', onMouseleaveSizeChanger);
    }
  }

  const bindTextChangers = (): void => {
    const textChangers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-cursor-size]');
    for (let i: number = 0; i < textChangers.length; i++) {
      textChangers[i].addEventListener('mouseenter', onMouseenterTextChanger);
      textChangers[i].addEventListener('mouseleave', onMouseleaveTextChanger);
    }
  }

  const resetCursor = (): void => {
    preventAutoScale = false;
    scale.baseValue = pxToPercent(20);
  };

  const unbindEvents = (): void => {
    document.removeEventListener('mousemove', onMousemove);
    document.removeEventListener('mouseleave', scaleOut);
    document.removeEventListener('mousedown', onMouseDown);
    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('mouseenter', onMouseUp);

    unbindLinks();
    unbindColorChangers();
    unbindSizeChangers();
    unbindTextChangers();
  }

  const unbindLinks = (): void => {
    const links: NodeListOf<HTMLElement> = document.querySelectorAll('a');
    for (let i: number = 0; i < links.length; i++) {
      links[i].removeEventListener('mouseenter', onMouseenter);
      links[i].removeEventListener('mouseleave', onMouseleave);
    }
  }

  const unbindColorChangers = (): void => {
    const colorChangers: NodeListOf<HTMLElement> = document.querySelectorAll('[cursor-color]');
    for (let i: number = 0; i < colorChangers.length; i++) {
      colorChangers[i].removeEventListener('mouseenter', onMouseenterColorChanger);
      colorChangers[i].removeEventListener('mouseleave', onMouseleaveColorChanger);
    }
  }

  const unbindSizeChangers = (): void => {
    const sizeChangers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-cursor-size]');
    for (let i: number = 0; i < sizeChangers.length; i++) {
      sizeChangers[i].removeEventListener('mouseenter', onMouseenterSizeChanger);
      sizeChangers[i].removeEventListener('mouseleave', onMouseleaveSizeChanger);
    }
  }

  const unbindTextChangers = (): void => {
    const textChangers: NodeListOf<HTMLElement> = document.querySelectorAll('[data-cursor-text]');
    for (let i: number = 0; i < textChangers.length; i++) {
      textChangers[i].removeEventListener('mouseenter', onMouseenterTextChanger);
      textChangers[i].removeEventListener('mouseleave', onMouseleaveTextChanger);
    }
  }
  //#endregion

  const onMousemove = (e: MouseEvent): void => {
    isOutsideViewport = false;

    mouse.x = e.clientX;
    mouse.y = e.clientY;

    if (!requestRef.current) requestRef.current = window.requestAnimationFrame(startRAF);
  }

  const onMouseenter = (): void => {
    preventAutoScale = true;

    gsap.to(scale, { value: scale.baseValue * scale.upModifier, duration: .5, ease: 'power4.out' });
  }

  const onMouseleave = (): void => {
    preventAutoScale = false;
    gsap.to(scale, { value: scale.baseValue, duration: .5, ease: 'power4.out' });
  }

  const onMouseenterColorChanger = (e: MouseEvent): void => {
    const color: string = (e.target as HTMLElement).getAttribute('data-cursor-color');
    gsap.set(cursorRef.current, { background: color });
  }

  const onMouseleaveColorChanger = (e: MouseEvent): void => {
    const parent: HTMLElement = (e.target as HTMLElement).parentElement.closest('[data-cursor-color]');
    let newColor: string = 'var(--hoverColor)';

    if (parent) {
      newColor = parent.getAttribute('data-cursor-color');
    }

    gsap.set(cursorRef.current, { background: newColor });
  }

  const onMouseenterSizeChanger = (e: MouseEvent): void => {
    const value: number = +(e.target as HTMLElement).getAttribute('data-cursor-size');

    scale.baseValue = value;
    gsap.to(scale, { value: scale.baseValue, duration: .5, ease: 'power4.out' });
  }

  const onMouseleaveSizeChanger = (e: MouseEvent): void => {
    const parent: HTMLElement = (e.target as HTMLElement).parentElement.closest('[data-cursor-size]');

    let newSize: number = pxToPercent(20);
    if (parent) {
      newSize = +parent.getAttribute('data-cursor-size');
    }

    preventAutoScale = false;
    scale.baseValue = newSize;
    gsap.to(scale, { value: newSize, duration: .5, ease: 'power4.out' });
  }

  const onMouseenterTextChanger = (e: MouseEvent): void => {
    const text: string = (e.target as HTMLElement).getAttribute('data-cursor-text');
    const textHolder = cursorRef.current.querySelector('.textHolder');

    gsap.killTweensOf(textHolder);
    gsap.set(textHolder, { autoAlpha: 0 });

    if (text) {
      textHolder.innerHTML = text.split('').map(t => `<span>${t}</span>`).join('');
    }

    gsap.to(textHolder, { autoAlpha: 1 });
    // gsap.to(textHolder.querySelectorAll('span'), {
    //   y: 0,
    //   stagger: .1,
    //   duration: .5,
    //   ease: 'power4.out'
    // })
  }

  const onMouseleaveTextChanger = (e: MouseEvent): void => {
    const textHolder = cursorRef.current.querySelector('.textHolder');
    gsap.killTweensOf(textHolder);
    gsap.to(textHolder, {
      autoAlpha: 0,
      onComplete: () => {
        textHolder.textContent = '';
      }
    });
  }

  const onMouseUp = (): void => {

    gsap.killTweensOf(scale);
    gsap.to(scale, {
      value: scale.baseValue,
      duration: .33,
      onUpdate: () => {
        gsap.set(cursorRef.current, { x: currentPos.x, y: currentPos.y, scale: scale.value });
      },
      onComplete: () => {
        preventAutoScale = false;
        gsap.set(cursorRef.current, { x: currentPos.x, y: currentPos.y, scale: scale.baseValue });
      },
      ease: 'back.out(5)'
    });
  }

  const onMouseDown = (): void => {
    preventAutoScale = true;
    gsap.killTweensOf(scale);

    gsap.to(scale, {
      value: scale.baseValue * scale.downModifier,
      duration: .5,
      onUpdate: () => {
        gsap.set(cursorRef.current, { x: currentPos.x, y: currentPos.y, scale: scale.value });
      },
      ease: 'power4.out'
    });
  }

  const scaleOut = (): void => {
    isOutsideViewport = true;
    gsap.to(scale, { value: 0, duration: .5, ease: 'power4.out' });
  }

  const startRAF = (): void => {
    if (!requestRef.current) return;

    animate();
    requestAnimationFrame(startRAF);
  }

  const stopRAF = (): void => {
    if (!requestRef.current) return;

    window.cancelAnimationFrame(requestRef.current);
    requestRef.current = null;
  }

  const animate = (): void => {
    const target: Vector = mouse;
    const distanceToMinScale: number = 100;
    currentPos.x -= (currentPos.x - target.x) * 0.3;
    currentPos.y -= (currentPos.y - target.y) * 0.3;

    updateFakeMouseCoords(currentPos);
    const distance: number = getDistance(target, currentPos);
    const clampedDistance = Clamp(distance, 0, distanceToMinScale);

    if (!preventAutoScale && !isOutsideViewport) {

      gsap.set(scale, { value: gearRatio(clampedDistance, 0, distanceToMinScale, scale.baseValue, Clamp(scale.baseValue * scale.downModifier, scale.minValue, 9999)) })
    }

    if (getDistance(target, currentPos) < 0.1) {
      updateFakeMouseCoords(target);
      stopRAF();
    }
  }

  const updateFakeMouseCoords = ({ x, y }: Vector): void => {
    gsap.set(cursorRef.current, { x, y, scale: scale.value });
  }
  //#endregion

  //#region Templating
  return (
    <StyledCursor ref={cursorRef}><div className="textHolder"></div></StyledCursor>
  )
  //#endregion
}