import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

export const SEO: React.FC<SEOProps> = ({ title, description, ogImage }) => {
  //#region Templating
  const location = useLocation();
  const hasDimensions = ogImage && ogImage.childImageSharp && ogImage.childImageSharp.resize && ogImage.childImageSharp.resize.width && ogImage.childImageSharp.resize.height;
  const imageUrl = (ogImage && ogImage.childImageSharp && ogImage.childImageSharp.resize && ogImage.childImageSharp.resize.src) || (ogImage && ogImage.publicURL) || '';
  return (
    <Helmet>
      <title>{title ? `${title} - Pact Studio` : 'Pact Studio'}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title ? `${title} - Pact Studio` : 'Pact Studio'} />
      <meta property="og:url" content={`https://workwithpact.com${location.pathname}`} />
      <meta property="og:image" content={imageUrl ? `${imageUrl.indexOf('//') === -1 ? `https://workwithpact.com${imageUrl}` : imageUrl}` : ''} />
      {hasDimensions ? <meta property="og:image:width" content={`${ogImage.childImageSharp.resize.width}`} /> : null}
      {hasDimensions ? <meta property="og:image:height" content={`${ogImage.childImageSharp.resize.height}`} /> : null}
      <meta property="og:type" content={location.pathname.indexOf('case-studies/') !== -1 || location.pathname.indexOf('etudes-de-cas/') !== -1 ? 'article' : 'website'} />
      <meta property="og:locale" content={location.pathname.indexOf('/fr/') === 0 ? 'fr_CA' : 'en_US'} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title ? `${title} - Pact Studio` : 'Pact Studio'} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
  //#endregion
}
export interface SEOProps {
  title: string;
  description?: string;
  ogImage?: any;
}