import { DefaultTheme } from 'styled-components';

const theme: AppTheme = {
  fonts: {
    primary: '',
    secondary: '',
    fontSize: 20
  },
  colors: {
    charcoal: '#2C2C2C',
    pebble: '#FFFAF3',
    coral: '#53A4FF',
    aqua: '#ACCEC8',
    cream: '#FDF1DC',
    salmon: '#F9F2EC',
    cloud: '#EEE3F4',
    solitude: '#E9F4FE',
    seafoam: '#E6F0F1',
    seaweed: '#EAF5EF',
    olive: '#EAEBDF',
    white: '#fff',
    black: '#000'
  },
  
  charcoal: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' },
  pebble: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' },
  aqua: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' },
  cream: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' },
  salmon: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' },
  cloud: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' },
  solitude: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' },
  seafoam: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' },
  seaweed: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' },
  olive: { text: '', hoverColor: '', background: '', loadingBG: '', loadingColor: '', cursor: '' }
}

theme.charcoal = {
  text: theme.colors.pebble,
  hoverColor: theme.colors.aqua,
  background: theme.colors.charcoal,
  loadingBG: theme.colors.pebble,
  loadingColor: theme.colors.charcoal,
  cursor: theme.colors.aqua
};

theme.pebble = {
  text: theme.colors.charcoal,
  hoverColor: theme.colors.aqua,
  background: theme.colors.pebble,
  loadingBG: theme.colors.charcoal,
  loadingColor: theme.colors.pebble,
  cursor: theme.colors.aqua
};

theme.aqua = {
  text: theme.colors.charcoal,
  hoverColor: theme.colors.pebble,
  background: theme.colors.aqua,
  loadingBG: theme.colors.charcoal,
  loadingColor: theme.colors.pebble,
  cursor: theme.colors.pebble
};

theme.cloud = {
  text: theme.colors.charcoal,
  hoverColor: theme.colors.aqua,
  background: theme.colors.cloud,
  loadingBG: theme.colors.charcoal,
  loadingColor: theme.colors.pebble,
  cursor: theme.colors.aqua
};

theme.cream = {
  text: theme.colors.charcoal,
  hoverColor: theme.colors.aqua,
  background: theme.colors.cream,
  loadingBG: theme.colors.charcoal,
  loadingColor: theme.colors.pebble,
  cursor: theme.colors.aqua

};

theme.salmon = {
  text: theme.colors.charcoal,
  hoverColor: theme.colors.aqua,
  background: theme.colors.salmon,
  loadingBG: theme.colors.charcoal,
  loadingColor: theme.colors.pebble,
  cursor: theme.colors.aqua

};

theme.solitude = {
  text: theme.colors.charcoal,
  hoverColor: theme.colors.aqua,
  background: theme.colors.solitude,
  loadingBG: theme.colors.charcoal,
  loadingColor: theme.colors.pebble,
  cursor: theme.colors.charcoal
};

theme.seafoam = {
  text: theme.colors.charcoal,
  hoverColor: theme.colors.aqua,
  background: theme.colors.seafoam,
  loadingBG: theme.colors.seafoam,
  loadingColor: theme.colors.charcoal,
  cursor: theme.colors.charcoal

};

theme.seaweed = {
  text: theme.colors.charcoal,
  hoverColor: theme.colors.aqua,
  background: theme.colors.seaweed,
  loadingBG: theme.colors.seaweed,
  loadingColor: theme.colors.charcoal,
  cursor: theme.colors.charcoal

};

theme.olive = {
  text: theme.colors.charcoal,
  hoverColor: theme.colors.aqua,
  background: theme.colors.olive,
  loadingBG: theme.colors.olive,
  loadingColor: theme.colors.charcoal,
  cursor: theme.colors.charcoal
};

interface AppTheme extends DefaultTheme {}
export default theme;