import styled from 'styled-components';
import { desktopHover, pxtorem } from '../../utils/Tools';

export const StyledFooter = styled.footer`
  position: relative;
  /* --color: var(--pebble); */

  .topWrapper {
    position: relative;
    padding: ${pxtorem(160)} var(--gutter) ${pxtorem(200)};
    &:before {
      content: '';
      position: absolute;
      height: 1px;
      top: 0;
      left: var(--gutter);
      right: var(--gutter);
      background: var(--color);
      opacity: 0.25;
    }
  }

  .mainWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }

  .section-form {
    width: 30.91%;
  }

  .form {
    &, * {
      cursor: none;
    }
    width: 100%;
  }

  .label {
    font: 400 ${pxtorem(20)}/${pxtorem(24)} var(--secondaryFont);
    text-transform: uppercase;
    display: block;
    margin-bottom: ${pxtorem(39)};
  }

  .input-holder {
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background: var(--color);
      opacity: .5;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .input {
    height: 40px;
    width: 100%;
    background: transparent;
    border-radius: 0;
    outline: 0;
    border: none;
    padding-right: 30px;
    padding-left: 0;
    font: 400 16px/19px var(--secondaryFont);
    &::placeholder {
      color: var(--color);
    }
  }

  .submit {
    transition: color .25s;
    position: absolute;
    right: -5px;
    top: 3px;
    background: transparent;
    border: none;
    padding: 0;
    width: 30px;
    /* cursor: pointer; */

    ${desktopHover('--color: var(--hoverColor)')}

    svg {
      width: 7px;
      height: 12px;
    }
  }

  .item {
    display: block;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .link {
    
    transition: color .25s;
    font: 400 ${pxtorem(20)}/${pxtorem(24)} var(--secondaryFont);
    text-transform: uppercase;
    display: inline-block;

    ${desktopHover('color: var(--hoverColor)')}
    &.active {
      color: var(--hoverColor);
    }
  }

  address {
    font: 400 1rem/${pxtorem(24)} var(--secondaryFont);
    font-style: regular;
    text-transform: uppercase;
    p {
      margin-top: 0;
      margin-bottom: ${pxtorem(23)};
    }

    a {
      transition: color .25s;
      /* --color: var(--aqua); */
      ${desktopHover('color: var(--hoverColor);')}
    }
  }

  .theme-pebble address a {
    --color: var(--aqua);
    --hoverColor: var(--charcoal);
  }

  .theme-charcoal address a {
    --color: var(--aqua);
    --hoverColor: var(--pebble);
  }

  .theme-aqua address a {
    --color: var(--pebble);
    --hoverColor: var(--charcoal);
  }

 

  @media (max-width: 850px) {
    .mainWrapper {
      flex-direction: column;
      gap: 48px;
    }

    .section {
      &-form {
        width: 100%;
        max-width: 515px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`