import React, { useEffect } from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import { Layout } from './Layout/Layout';

export const Page: React.FC<PageProps> = ({
  children,
  background = 'charcoal',
  language = 'en',
  slugs = { en: '/', fr: '/fr' },
  showMissingFrenchBanner = false,
  overlapHeader = false
}) => {
  //#region Hooks / Lifecycles
  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }

  //#endregion

  //#region Templating
  return (
    <StyleSheetManager disableVendorPrefixes={process.env.NODE_ENV === 'development'}>
      <ThemeProvider theme={theme}>
        <Layout theme={background} language={language} slugs={slugs} showMissingFrenchBanner={showMissingFrenchBanner} headerOverlap={overlapHeader}>
          {children}
        </Layout>
      </ThemeProvider>
    </StyleSheetManager>
  )
  //#endregion
}

interface PageProps {
  background?: ThemeBG;
  overlapHeader?: boolean;
  showMissingFrenchBanner?: boolean;
  language?: lang;
  slugs: { en: string; fr: string };
}