export const paths = {
  en: {
    prefix: '',
    caseStudies: 'case-studies',
    stories: 'stories',
    jobPosting: 'jobs'
  },
  fr: {
    prefix: 'fr',
    caseStudies: 'etudes-de-cas',
    stories: 'articles',
    jobPosting: 'emplois'
  }
}