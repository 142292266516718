import React, { useContext } from 'react';
import { graphql, Link, StaticQuery, StaticQueryDocument } from 'gatsby';
import { getLocaleMdData } from '../../utils/Tools';
import { PactContext } from '../PactContext';
import { Wrapper } from '../../styles/Wrapper.style';
import { LanguageSwitcher } from '../LanguageSwicher/LanguageSwitcher';
import ReactMarkdown from 'react-markdown';
import { StyledFooter } from './Footer.style';
import ChevronRightSVG from '../../styles/svg/ChevronRight.svg';


const Template = ({ data }: LocalizedMarkdownData<FooterData>) => {
  const { language, pageTheme } = useContext(PactContext);
  //#region Variables
  const _data = getLocaleMdData<FooterData>(data);
  const { newsletterTitle, newsletterPlaceholder, addresses, footer_links } = _data[language];
  //#endregion

  //#region Templating
  return (
    <StyledFooter>
      <Wrapper className="topWrapper">
        <Wrapper width={1666} gutter={0} className={`mainWrapper theme-${pageTheme}`}>
          <div className="section section-form">
            <form
              action="https://workwithpact.us1.list-manage.com/subscribe/post?u=d56db1bc074190f9c5c1c6e29&amp;id=08649c3d52"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="form"
              target="_blank" noValidate>
              <label>
                <span className="label">{newsletterTitle}</span>
                <div className="input-holder">
                  <input type="email" required className="input" name="EMAIL" placeholder={newsletterPlaceholder} />
                  <input
                    type="hidden"
                    name={language === 'fr' ? 'group[382829][1]' : 'group[382829][2]'}
                    value={language === 'fr' ? '1' : '2'} />
                  <button type="submit" className="submit">
                    <ChevronRightSVG />
                  </button>
                </div>
              </label>
            </form>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
              <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
            </div>
            <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
              <input type="text" readOnly name="b_d56db1bc074190f9c5c1c6e29_08649c3d52" tabIndex={-1} value="" />
            </div>
          </div>

          <div className="section">
            <ul className="items">
              {footer_links.map((link, i: number) => (
                <li className="item" key={`footer-link-${i}`}>
                  <Link to={link.link_url} className="link" activeClassName="active">{link.link_label}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="section">
            <address>
              <ReactMarkdown>{addresses}</ReactMarkdown>
            </address>
          </div>

          <div className="section">
            <LanguageSwitcher />
          </div>
        </Wrapper>
      </Wrapper>
    </StyledFooter>
  )
  //#endregion
}

export const Footer: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/misc/footer.md/"}) {
        fields {
          locales {
            en {
              newsletterTitle
              newsletterPlaceholder
              footer_links {
                link_label
                link_url
              }
              addresses
            }
            fr {
              newsletterTitle
              newsletterPlaceholder
              footer_links {
                link_label
                link_url
              }
              addresses
            }
          }
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}

interface FooterData {
  newsletterTitle: string;
  newsletterPlaceholder: string;
  footer_links: {
    link_label: string;
    link_url: string;
  }[];
  addresses: string;
}