import React, { useEffect } from 'react';
import { StyledBurger } from './Burger.style';

export const Burger: React.FC<BurgerProps> = ({ isActive = false, onClick }) => {
  //#region Templating
  return (
    <StyledBurger onClick={onClick} isActive={isActive} type="button" className="burger">
      <span className="top"></span>
      <span className="bottom"></span>
    </StyledBurger>
  )
  //#endregion
}

export interface BurgerProps {
  onClick?(e: React.MouseEvent): void;
  isActive: boolean;
}