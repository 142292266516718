import styled from 'styled-components';

export const StyledLoadingAnimation = styled.div<StyledLoadingAnimationProps>`
  ${p => p.visible ? 'display: block;' : 'display: none;'}
  width: 100%;
  height: var(--window-height);
  position: fixed;
  top: 0;
  left: 0;
  background: ${p => p.theme[p.pageTheme].loadingBG};
  z-index: 99;
  color: ${p => p.theme[p.pageTheme].loadingColor};
  overflow: hidden;

  .wrapper {
    width: 100%;
    height: var(--window-height);
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
  }

  svg {
    overflow: visible;
  }

  path {
    display: block;
    transform: translateY(-25px);
    opacity: 0;
    visibility: hidden;
    &:nth-child(even) {
      transform: translateY(25px)
    }
  }
`

interface StyledLoadingAnimationProps {
  visible: boolean;
  pageTheme: ThemeBG;
}