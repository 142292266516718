import styled from 'styled-components';

export const StyledPactImage = styled.div<StyledPactImageProp >`
  ${p => p.maxWidth && p.maxWidth !== 0
    ? `
      --maxWidth: ${p.maxWidth}px;
      max-width: var(--maxWidth);`
    : ''}
    
  line-height: 0;
`

interface StyledPactImageProp {
  maxWidth: number;
}