import { Link } from 'gatsby';
import gsap from 'gsap';

import React, { useEffect, useRef, useState, useContext } from 'react';
import DeviceInfo from '../../utils/DeviceInfo';
import { paths } from '../../utils/paths';
import { Clamp, gearRatio } from '../../utils/Tools';
import { LanguageSwitcher } from '../LanguageSwicher/LanguageSwitcher';
import { PactContext } from '../PactContext';
import { PactImage } from '../PactImage/PactImage';
import { StyledMenu } from './Menu.styled';

const easing: number = 0.1;

const isMobile: boolean = DeviceInfo.check()?.isMobile || false;

export const Menu: React.FC<MenuProps> = ({ active, links = [], bottom_links = [], counts }) => {
  //#region Hooks / Lifecycles
  const { language } = useContext(PactContext);

  const [currentImage, setCurrentImage] = useState(null);

  const floatingImageRef = useRef<HTMLDivElement>();

  const isOnTicker = useRef<boolean>(false);

  const mousePos = useRef<Point>({ x: 0, y: 0 });

  const currentMousePos = useRef<Point>({ x: 0, y: 0 });

  const showImage = useRef<boolean>(false);

  const images: string[] = links.filter(link => link.hoverImage ? true : false).map(link => link.hoverImage.childImageSharp.gatsbyImageData.images.sources[0].srcSet);

  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, [active]);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => {
    if (isMobile) return;
    preloadImages();
    if (active) {
      bindEvents();
    } else {
      destroy();
    }
  }

  const destroy = (): void => {
    if (isMobile) return;
    unbindEvents();
    gsap.ticker.remove(tick);
  }

  const preloadImages = (): void => {
    images.forEach(srcset => {
      const img: HTMLImageElement = new Image();
      img.srcset = srcset;
    });
  }

  const bindEvents = (): void => {
    document.addEventListener('mousemove', onMouseMove);
  }

  const unbindEvents = (): void => {
    document.removeEventListener('mousemove', onMouseMove);
  }

  const onMouseMove = (e: MouseEvent): void => {
    mousePos.current = { x: e.pageX, y: e.pageY };

    if (!isOnTicker.current) {
      gsap.ticker.add(tick);
    }
  }

  const tick = (): void => {
    currentMousePos.current.x -= (currentMousePos.current.x - mousePos.current.x) * easing;
    currentMousePos.current.y -= (currentMousePos.current.y - mousePos.current.y) * easing;

    const dX: number = mousePos.current.x - currentMousePos.current.x;
    const dY: number = mousePos.current.y - currentMousePos.current.y;
    const distance = Math.sqrt(dX * dX + dY * dY);

    const clampedDistance = Clamp(dX, -300, 300);
    // const rotation = gearRatio(clampedDistance, -300, 300, -15, 60);
    const rotation = gearRatio(clampedDistance, -300, 300, -15, 15);

    if (distance <= 0.1) {
      gsap.ticker.remove(tick);
      setImagePosition(mousePos.current, rotation);
    } else {
      setImagePosition(currentMousePos.current, rotation);
    }

  }

  const setImagePosition = (pos: Point, rotation: number = 0): void => {
    gsap.set(floatingImageRef.current, {
      rotate: rotation,
      x: pos.x,
      y: pos.y - window.scrollY
    })
  }

  const onLinkMouseEnter = (image: any): void => {
    if (!image) return;

    setCurrentImage(image);
    floatingImageRef.current.classList.add('visible');

  }

  const onLinkMouseLeave = (): void => {
    floatingImageRef.current.classList.remove('visible');
  }

  //#endregion

  //#region Templating
  return (
    <StyledMenu>
      <ul className="items">
        {links.map((link, i: number) => {
          const { link_label, link_url, hoverImage } = link;
          let count = null;

          if (link_url.includes(paths[language].caseStudies)) {
            count = counts['cases'];
          } else if (link_url.includes(paths[language].stories)) {
            count = counts['stories'];
          }

          return (
            <li
              className="item"
              key={`header-link-${i}`}
              onMouseEnter={!isMobile ? onLinkMouseEnter.bind(null, hoverImage) : null}
              onMouseLeave={!isMobile ? onLinkMouseLeave : null}>
              <Link to={link_url} className="link">{link_label}{count && <span className="count">({count})</span>}</Link>
            </li>
          )
        })}
      </ul>
      <div className={`floatingImage ${showImage.current ? 'floatingImage--visible' : ''}`} ref={floatingImageRef}>
        {currentImage && <PactImage image={currentImage} alt="" />}
      </div>
      <div className={`menu-bottom`}>
        <LanguageSwitcher />
        <span className="dot">&middot;</span>
        {bottom_links.map(({link_label, link_url}, i: number) => <Link to={link_url} className="bottom-link" key={link_url}>{link_label}</Link>)}
      </div>
    </StyledMenu>
  )
  //#endregion
}

interface MenuProps {
  active: boolean;
  counts: {
    cases: number;
    stories: number;
  }
  links: {
    link_label: string;
    link_url: string;
    hoverImage: any;
  }[]
  bottom_links: {
    link_label: string;
    link_url: string;
  }[];
}

interface Point {
  x: number;
  y: number;
}