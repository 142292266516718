import styled from 'styled-components'
import { Wrapper } from '../../styles/Wrapper.style'
import { desktopHover, pxtorem } from '../../utils/Tools'

export const StyledHeader = styled.header<StyledHeaderProp>`
  --logoHeight: 36px;
  position: fixed;
  width: 100%;
  top: 0;
  height: var(--window-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  pointer-events: none;

  .container {
    position: relative;
    z-index: 11;
  }

  .banner {
    padding: ${pxtorem(17)} var(--gutter) ${pxtorem(18)};
    background: var(--charcoal);
    color: var(--pebble);
    text-align: center;
    font: 400 1rem/150% var(--secondaryFont);

    ${p => p.menuOpened ? `opacity: 0;` : null}
  }
  
  .wrapper {
    --verticalGutter: ${pxtorem(60)};
    pointer-events: all;
    transition: background .55s cubic-bezier(.22, 1, .36, 1), padding .75s cubic-bezier(.22, 1, .36, 1);
    /* ${p => p.menuOpened ? `background: var(--background)` : null}; */
    padding-top: var(--verticalGutter);
    padding-bottom: var(--verticalGutter);

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      transition: inherit;
      z-index: -1;
    }
  }
  
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
      flex: 1 1 0;
    }
  }

  .wrapper {
    .header--scrolled:not(.menu-opened) & {
      --verticalGutter: 20px;
      
      &:before {
        background: var(--background);
      }
    }
  }

  .header-center {display: flex; justify-content: center;}
  .header-right {
    transition: opacity .55s;

    display: flex;
    justify-content:
    flex-end;

    svg {
      display: none;
    }

    .menu-opened & {
      opacity: 0;
      pointer-events: none;
    }
  }
    
  a {
    color: var(--color);
    transition: color .25s;
    ${desktopHover(`
      --color: var(--hoverColor);    
    `)}
  }
  
  .logo {
    display: block;
    svg {
      vertical-align: top;
      width: 78px;
      height: var(--logoHeight);
    }
  }

  .link {
    font: 400 ${pxtorem(16)}/${pxtorem(20)} var(--secondaryFont);
    text-transform: uppercase;
  }

  @media (max-width: 768px) {
    --logoHeight: 32px;

    .wrapper {
      --verticalGutter: 30px;
    }

    .logo svg {
      width: 68px;
    }

    .header-right {
      span {
        display: none;
      }

      svg {
        display: block;
        width: 20px;
      }
    }
  }
`

interface StyledHeaderProp {
  menuOpened: boolean;
}