import styled from 'styled-components';

export const StyledCursor = styled.div`
  --radius: 110px;
  transition: background .25s;
  width: var(--radius);
  height: var(--radius);
  border-radius: 100%;
  background: var(--cursorColor);
  position: fixed;
  top: 0;
  pointer-events: none;
  z-index: 999;
  transform: translate(-50%, -50%) scale(0);

  @media (hover: none) and (pointer: coarse) {
    display: none;
  }

  @media (hover: hover) and (pointer: coarse) {
    display: none;
  }



  .textHolder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-transform: uppercase;
    font-size: 16px;
    line-height: normal;
    font-family: var(--secondaryFont);
    white-space: nowrap;
    span {
      color: var(--charcoal);
      display: inline-block;
      /* transform: translateY(2px); */
    }
  }
`;