import React, { useEffect, useContext, useState } from 'react';
import { graphql, Link, StaticQuery, StaticQueryDocument } from 'gatsby';
import { LogoSvg } from '../../styles/svg/logo.svg';
import { Wrapper } from '../../styles/Wrapper.style';
import { Burger } from '../Burger/Burger';
import { StyledHeader } from './Header.style';
import { getLocaleMdData, getMdData } from '../../utils/Tools';
import { Menu } from '../Menu/Menu';
import { PactContext } from '../PactContext';
import MailSVG from '../../styles/svg/Mail.svg';
import { paths } from '../../utils/paths';


const Template = ({ data, showMissingFrenchBanner = false }: HeaderTemplateProps) => {
  //#region Hooks / Lifecycles
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const { language } = useContext(PactContext);
  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables
  const _data = getLocaleMdData(data);
  const { allCaseStudies, allStories } = data;

  const { header_cta_label, header_cta_link, header_links, header_bottom_links } = _data[language] || {};
  //#endregion

  //#region Functions
  const init = (): void => {
    if (typeof window === undefined) return;
    bindEvents();
    onScroll();
  }

  const destroy = (): void => {
    if (typeof window === undefined) return;
    unbindEvents();
    setMenuOpened(false);
    document.body.classList.remove('menu-opened');
  }

  const bindEvents = (): void => {
    window.addEventListener('scroll', onScroll);
  }

  const unbindEvents = (): void => {
    window.removeEventListener('scroll', onScroll);
  }

  const onScroll = (): void => {
    document.body.classList.toggle('header--scrolled', window.scrollY > 50);
  }

  const onBurgerClick = (e: React.MouseEvent): void => {
    setMenuOpened(!menuOpened);
    document.body.classList.toggle('menu-opened', !menuOpened);
  }
  //#endregion

  //#region Templating
  return (
    <StyledHeader menuOpened={menuOpened}>
      <div className="container">
        {showMissingFrenchBanner &&
          <div className="banner">Ce contenu est uniquement disponible en anglais; la version francophone suivra sous peu.</div>
        }
        <Wrapper className="wrapper">
          <div className="top">
            <div className="header-left">
              <Burger isActive={menuOpened} onClick={onBurgerClick} />
            </div>
            <div className="header-center">
              <Link to={`/${paths[language].prefix}`} className="logo">
                <LogoSvg />
              </Link>
            </div>
            <div className="header-right">
              <Link to={header_cta_link} className="link">
                <span>{header_cta_label}</span>
                <MailSVG />
              </Link>
            </div>
          </div>
        </Wrapper>
      </div>
      <Menu active={menuOpened} links={header_links} bottom_links={header_bottom_links} counts={{ cases: allCaseStudies.totalCount, stories: allStories.totalCount }} />
    </StyledHeader>
  )
  //#endregion
}

export const Header: React.FC<HeaderProps> = ({ showMissingFrenchBanner }) => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/misc/header.md/"}) {
        fields {
          locales {
            en {
              header_links {
                link_label
                link_url
                hoverImage {
                  childImageSharp {
                    gatsbyImageData(
                      width: 726
                      layout: FULL_WIDTH
                      placeholder: DOMINANT_COLOR
                      transformOptions: {fit: COVER}
                    )
                  }
                }
              }
              header_bottom_links {
                link_label
                link_url
              }
              header_cta_label
              header_cta_link
            }
            fr {
              header_links {
                link_label
                link_url
                hoverImage {
                  childImageSharp {
                    gatsbyImageData(
                      width: 726
                      layout: FULL_WIDTH
                      placeholder: DOMINANT_COLOR
                      transformOptions: {fit: COVER}
                    )
                  }
                }
              }
              header_bottom_links {
                link_label
                link_url
              }
              header_cta_label
              header_cta_link
            }
          }
        }
      }

      allCaseStudies: allMarkdownRemark(filter: {frontmatter: {type: {eq: "case-study"}}}) {
        totalCount
      }

      allStories: allMarkdownRemark(filter: {frontmatter: {type: {eq: "story"}}}) {
        totalCount
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template showMissingFrenchBanner={showMissingFrenchBanner} data={data} />} />
  //#endregion
}

interface HeaderData {
  header_links: {
    link_label: string;
    link_url: string;
    hoverImage: string;
  }[];
  header_bottom_links: {
    link_label: string;
    link_url: string;
  }[];
  header_cta_link: string;
  header_cta_label: string;
}

interface HeaderProps {
  showMissingFrenchBanner?: boolean;
}
interface HeaderTemplateProps {
  data: LocalizedAllMarkdownDataRelation<HeaderData>['data'];
  showMissingFrenchBanner?: boolean;
}