import { Link } from 'gatsby';
import React, { useContext, useRef } from 'react';
import { PactContext } from '../PactContext';
import { StyledLanguageSwitcher } from './LanguageSwitcher.style';

let LanguageSwitcherIndex: number = 0;
export const LanguageSwitcher: React.FC = () => {
  //#region Hooks / Lifecycles
  LanguageSwitcherIndex++;
  const { slugs, language } = useContext(PactContext);

  const availableLanguages = useRef<lang[]>(['en', 'fr']);
  //#endregion

  //#region Templating
  return (
    <StyledLanguageSwitcher>
      {availableLanguages.current.map((l: lang) => (
        <Link
          className={`lang ${l === language ? 'active' : ''}`}
          to={slugs[l]} key={`lang-${LanguageSwitcherIndex}-${l}`}>{l}</Link>
      ))}
    </StyledLanguageSwitcher>
  )
  //#endregion
}