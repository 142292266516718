import React from 'react';

export const PactContext = React.createContext<PactContextData>({
  language: 'en',
  pageTheme: 'charcoal',
  slugs: {
    en: '/',
    fr: '/fr'
  }
});

type lang = 'en' | 'fr';

interface PactContextData {
  language: lang;
  pageTheme: ThemeBG;
  slugs: {
    en: string;
    fr: string;
  }
}