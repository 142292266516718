import React, { useEffect, useRef, useState, useCallback } from 'react';
import { VisibilityManager } from '../../services/VisibilityManager';
import { StyledShowOnScroll, StyledShowOnScrollProps } from './ShowOnScroll.styled';

export default function ShowOnScroll({
  children,
  passive = false,
  className = '',
  visibleClassName = 'visible',
  delay = 0,
  transitionDuration = '1.5s',
  easing = 'cubic-bezier(.22, 1, .36, 1)',
  observerOptions,
  autoUnsubscribe = true,
  reset = true,
  onNoLongerVisible,
  onVisible
}: React.PropsWithChildren<ShowOnScrollProps>): JSX.Element {

  const visibilityManager: VisibilityManager = VisibilityManager.getInstance();

  //#region Hooks / LifecyclesLifecycles
  const visibilitySub = useRef<Symbol>();

  const wrapperRef = useRef<HTMLDivElement>();

  const timeoutRef = useRef<any>();

  const [wrapperClassName, setWrapperClassName] = useState<string>();

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const onIntersectionCB = useCallback(onIntersection, [isVisible]);

  useEffect(() => {
    init();

    return () => {
      destroy();
    };
  }, [isVisible]);
  //#endregion

  //#region Functions
  function init(): void {
    visibilitySub.current = visibilityManager.subscribe(wrapperRef.current, onIntersectionCB, observerOptions);
  }

  function destroy(): void {
    clearTimeout(timeoutRef.current);
    unsubscribeVisibilityObserver();
  }

  function unsubscribeVisibilityObserver(): void {
    if (visibilitySub.current) {
      visibilityManager.unsubscribe(visibilitySub.current);
      visibilitySub.current = null;
    }
  }

  function onIntersection(entry: IntersectionObserverEntry): void {
    if (entry.isIntersecting) {
      timeoutRef.current = setTimeout(() => {
        show();

        if (onVisible) {
          onVisible();
        }
      }, delay);

      if (autoUnsubscribe) {
        visibilityManager.unsubscribe(visibilitySub.current);
      }
    } else if (!autoUnsubscribe && isVisible) {
      timeoutRef.current = setTimeout(() => {
        hide();

        if (onNoLongerVisible) {
          onNoLongerVisible();
        }
      }, delay);
    }
  }

  function show(): void {
    setWrapperClassName(visibleClassName);
    setIsVisible(true);
  }

  function hide(): void {
    if (reset) {
      setWrapperClassName('');
    }
    setIsVisible(false);
  }

  //#endregion

  return (
    <StyledShowOnScroll
      className={wrapperClassName + (className ? ' ' + className : '')}
      passive={passive}
      duration={transitionDuration}
      delay={delay}
      easing={easing}
      isVisible={isVisible}
      ref={wrapperRef}
    >
      {children}
    </StyledShowOnScroll>
  );
}

interface ShowOnScrollProps extends Pick<Partial<StyledShowOnScrollProps>, 'delay' | 'passive'> {
  className?: string;
  visibleClassName?: string;
  transitionDuration?: string;
  easing?: string;
  observerOptions?: IntersectionObserverInit;
  autoUnsubscribe?: boolean;
  reset?: boolean;
  onVisible?(): void;
  onNoLongerVisible?(): void;
}
